export const contentTypes = {
  kitSoAcoustic: 410,
  kitSoTodBeats: 550,
  kitDuAcoustic: 520,
  kitDuTodBeats: 670,
  movilBeats: 89,

  accountId: '',
  apiKey: 'kut86dK00a8qVw0OvAyvp51b28',
  merchantId: '691281',
  moneda: 'PEN'
};

export const emailsBeats = {
  correo: 'contacto@beatsmusica.com'
};

export const url = {
  link: 'https://beats.navike21.com'
}
