import React, { Component } from 'react';
import Logo from '../../images/logo.svg';
import VideoBeats from '../home/videoBeats';

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPortada: false
    };
  }
  showPortada = () => {
    this.setState({
      showPortada: !this.state.showPortada
    });
  };
  render() {
    return (
      <footer className="section_middle_center w_100 font_tiny whiteColor font_light darkBlueColor spaceInBottom_medium">
        <aside className="w_100 section_middle_center graybackground spaceInVertical_biggest marginBottom_small">
          <a
            className="purpleColor2 font_biggest marginHorizontal_smaller"
            href="https://www.facebook.com/beatsmusica"
            target="_blank" rel="noopener noreferrer"
          >
            <i className="fab fa-facebook-square" />
          </a>
          <a
            className="purpleColor2 font_biggest marginHorizontal_smaller"
            href="https://www.instagram.com/beats_musica/"
            target="_blank" rel="noopener noreferrer"
          >
            <i className="fab fa-instagram" />
          </a>
          <a
            className="purpleColor2 font_biggest marginHorizontal_smaller"
            href="http://www.twitter.com/beats_musica"
            target="_blank" rel="noopener noreferrer"
          >
            <i className="fab fa-twitter" />
          </a>
          <a
            className="purpleColor2 font_biggest marginHorizontal_smaller"
            href="https://www.youtube.com/channel/UCfcnNZ02oVic9zONXVMaAlQ/videos?view_as=subscriber"
            target="_blank" rel="noopener noreferrer"
          >
            <i className="fab fa-youtube" />
          </a>
          <a
            className="purpleColor2 font_biggest marginHorizontal_smaller"
            href="https://soundcloud.com/beatsmusica"
            target="_blank" rel="noopener noreferrer"
          >
            <i className="fab fa-soundcloud"></i>
          </a>
        </aside>
        <div className="w_100 section_middle_center marginVertical_medium">
          <img src={Logo} alt="LogoBeats" className="img_small_mobile img_medium" />
        </div>
        <p className="w_80 align_center">
          Todas las canciones son propiedad intelectual de beats. Los derechos de uso de la canción
          se comparten entre la empresa beats y el cliente.
        </p>
        <div className="wrappVideoBeats section_middle_center">
          <VideoBeats showImg={this.showPortada} />
        </div>
      </footer>
    );
  }
}
